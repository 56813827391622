<template>
  <div class="pd-20 w-750" style="margin: 0 auto">
    <h1>卡牌导入教程</h1>
    <h3>第一步：下载卡牌资源</h3>
    <p>卡牌的来源可以别人或者网上找到相关资源，通过QQ发送到手机，或者浏览器下载</p>
    <p>注：微信会改变卡牌后缀，不要用微信发送</p>
    <br>
    <h3>第二步：打开其他应用打开弹窗</h3>
    <p>文件夹：长按，点击共享</p>
    <p>QQ：点击其他应用打开</p>
    <p>打开后会出现以下页面，点击更多</p>
    <p>找到Anki探索版并点击打开</p>
    <p><img src="/static/images/1.png" style="width: 80%;max-width: 400px"/></p>
    <p><img src="/static/images/2.png" style="width: 80%;max-width: 400px"/></p>
    <br>
    <h3>第三步：进入Anki记忆后，等待1分钟左右下拉刷新</h3>
    <p><img src="/static/images/3.png" style="width: 80%;max-width: 400px"/></p>
  </div>
</template>

<script>
  export default {
    name: "index"
  }
</script>

<style scoped>

</style>
