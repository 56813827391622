import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/layout.css'

Vue.use(Vant);
Vue.use(VueRouter)


import Cancellation from '@/views/cancellation';
import AddCardGuide from '@/views/add-card-guide';
import Download from '@/views/download';
import Contact from '@/views/contact';
import About from '@/views/about';
import Index from '@/views/index';

let router = new VueRouter({
  routes: [
    {
      path: '/', component: Index, meta: {
        title: '主页'
      },
    },
    {
      path: '/cancellation', component: Cancellation, meta: {
        title: '账号注销'
      },
    }, {
      path: '/AddCardGuide', component: AddCardGuide, meta: {
        title: '教程'
      }
    }, {
      path: '/Download', component: Download, meta: {
        title: '下载'
      }
    }, {
      path: '/About', component: About, meta: {
        title: '关于'
      }
    }, {
      path: '/Contact', component: Contact, meta: {
        title: 'ANKI微信交流群&意见反馈'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


Vue.config.productionTip = false

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
