<template>
  <div class="pd-20">
    <div class="index-module_header_3qjZG">
      <div class="index-module_title_1s0gC">
        <h1 id="article-title" class="index-module_articleTitle_2xHPX" style="font-size:36px;color:#262626;">
          <div class="index-module_header_3qjZG">
            <div class="index-module_title_1s0gC">
              <h1  class="index-module_articleTitle_2xHPX" style="font-size:36px;color:#262626;">
                ANKI微信交流群&amp;意见反馈
              </h1>
            </div>
          </div>
          <div>
            <div class="yuque-doc-content">
              <div class="lake-engine-view lake-typography-classic">
                <p style="font-size:15px;color:#404040;">
                  添加我的微信，拉你进群
                </p>
                <ol style="font-size:15px;color:#404040;">
                  <li>
                    扫一扫下图
                  </li>
                  <li>
                    或输入微信号添加：woshikakali
                  </li>
                </ol>
                <div>
                  <img src="/static/images/wxqrcode.png" width="300">
                </div>
              </div>
            </div>
          </div>
          <br/>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "contact"
  }
</script>

<style scoped>

</style>
