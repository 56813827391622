<template>
  <div class="pd-20">
    <div class="index-module_header_3qjZG">
      <div class="index-module_title_1s0gC">
        <h1 id="article-title" class="index-module_articleTitle_2xHPX" style="font-size:36px;color:#262626;">
          关于ANKI探索版团队
        </h1>
      </div>
    </div>
    <div>
      <div class="yuque-doc-content">
        <div class="lake-engine-view lake-typography-classic">
          <p style="font-size:15px;color:#404040;">
            你好，我是ANKI探索版团队发起人<strong>卡卡里，我是ANKI的老用户，也是一名产品经理</strong>，因为对ANKI的热爱，我利用工作之余的时间筹划了两件事情，一<strong>个是组建开发者团队来解决ANKI现存的问题和全面提升ANKI的产品体验，另外一个就是输出ANKI的入门教程，让更多人知道该ANKI有什么用，如何用。</strong>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            ANKI是一款及其优秀的学习工具，但如果你是中国的ANKI用户应该知道，<strong>ANKI在国内使用是存在诸多问题的，比如同步极慢、操作界面中文翻译不好懂，软件手册、插件等等所有资料都是英文</strong>，这对国内大多数人来说无疑是一堵无形高墙，隔绝了许多人。
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            <span></span>造成这些问题的原因，我非常理解，ANKI的作者是一名国外的工程师，开始一个人带头完成了这款应用的所有事情，你可以想象一下，本来需要几十人的互联网团队才能做好的事情，他一个人带头完成了，他不可能把用户体验、美感这些考虑得那么周到的。
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            另外<strong>ANKI不仅仅是一款闪卡记忆工具，它更是一种新的学习方法</strong>，如何让用户在使用产品中无形地学到这套新方法，这是一件不容易的事，这将非常考验产品设计者的系统设计能力。
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            这些问题从我2016年开始知道ANKI，到现在已经存在4年多了，一直没人去解决，而我又是一名产品经理职业的ANKI用户，ANKI又是开源的，所以我产生了一个想法，<strong>如果能聚集一群热爱和有能力的ANKI资深用户一起来设计、开发ANKI，把它改造成合适国人使用、体验更好的学习工具，它可能会变得比原ANKI更加出色、更加有用、更加流行。</strong>
            这让我想到了一个学习对象，在2011年左右的时候Android官方系统版本体验是非常粗糙的，小米公司将开源的Android系统改进成了国人怒赞的MIUI系统，我们又未尝不可呢？
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            <strong>让一群资深用户一起开发应用给自己用，又普惠到所有普通用户的这种方式，在软件行业并不是一件常见的事</strong>，没有很好的学习对象，这种合作方式，如果没有合适的流程和规则，很难让一群人的力量往一处使。
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            还有如何给这些贡献了力量的用户获得正向的反馈，如何让ANKI志愿者团队变成一个可持续运转的系统，不会因为某个人离开了这个系统就会奔溃，这个系统可能只能根据实际情况慢慢沉淀出来了。
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <h1 id="ov2Op" style="font-size:28px;">
            ANKI探索版<strong>项目计划</strong>
          </h1>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <strong>让用户即是产品作者又是使用者，对于这个构思的实现我内心是非常期待的，但万丈高楼平地起，回到此时此刻，第一问题就是如何开展这个项目，我分了三个阶段。</strong>
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <blockquote style="margin-left:0px;">
            <ol style="font-size:15px;color:#404040;">
              <li>
                <strong>项目启动阶段：</strong>由我个人自费聘请开发者完成ANKI探索版部分优化，优先解决国内同步慢的问题，希望上线后能到带头作用，吸引更多人加入ANKI探索版团队。<br/>
              </li>
            </ol>
            <p style="font-size:15px;color:#404040;">
              <br/>
            </p>
            <ol style="font-size:15px;color:#404040;">
              <li>
                <strong>人员招募阶段：</strong>通过知乎、QQ群、网站等各个渠道招募具备不同能力的成员，形成一个虚拟的、自由的、以兴趣驱动的组织，具备开发ANKI各端的能力。<br/>
              </li>
            </ol>
            <p style="font-size:15px;color:#404040;">
              <br/>
            </p>
            <ol style="font-size:15px;color:#404040;">
              <li>
                <strong>自动化运作阶段：</strong>团队成员达到一定数量，具备从产品设计、产品开发、产品运营各环节的能力，探索出合适的自动化运作流程、规则，各方面都能向好发展。<br/>
              </li>
            </ol>
          </blockquote>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            目前已经进入了第一个阶段，在找了几十个开发者沟通后终于聘请到了4位合适的开发者，主要解决同步问题和一些细节问题，不过各项费加在一起有点远超出我的预算。ANKI探索版的云存储是阶梯收费的，我希望通过此获得的费用能覆盖掉服务器、云存储和带宽的成本费用。但其实也能预测到付费量不会有多少，国内还是习惯于免费的产品服务，我也在思考其他合适收费点，有些人可能会反感收费，觉得这是不正义的，但真想让ANKI探索版不断发展下去，商业手法又是比较有必要的。如果未来有其他收入来源，云存储我们就会考虑免费！
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <h1 id="MKVkA" style="font-size:28px;">
            ANKI探索版<strong>团队工作目标</strong>
          </h1>
          <p style="font-size:15px;color:#404040;">
            <strong>为了让大家更加明确ANKI</strong>探索版<strong>团队的工作目标，下面罗列些我们目前待解决的问题</strong>
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <blockquote style="margin-left:0px;">
            <ul style="font-size:15px;color:#404040;">
              <li>
                ✅<strong>同步问题：</strong>解决ANKI因为服务器在海外导致国内同步慢、不稳定的问题<br/>
              </li>
              <li>
                <strong>✅体验问题（已提升，持续中）：</strong>解决体验差、界面丑、翻译难懂的问题，这是诸多用户经常吐槽的问题，毕竟人们都向往美好的事物<br/>
              </li>
              <li>
                <strong>✅易用性问题（已提升，持续中）：</strong>提高ANKI的易用性，让新人可以更好入门，兼顾简单和强大<br/>
              </li>
              <li>
                ✅丰富学习资源<strong>（已丰富，持续中）</strong><br/>
              </li>
            </ul>
          </blockquote>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <ul style="font-size:15px;color:#404040;">
            <li>
              <strong>我们短期的目标（约6-8月）：解决国内用户使用ANKI存在的明显问题</strong><br/>
            </li>
            <li>
              <strong>我们长远的目标（约2-3年）：以集体的智慧和能量去升级改造ANKI，让ANKI变成一款更优秀、更多人使用的学习产品</strong><br/>
            </li>
          </ul>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <p style="font-size:15px;color:#404040;">
            &nbsp;
          </p>
          <h1 id="MkP8t" style="font-size:28px;">
            <strong><br/>
            </strong>
          </h1>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            下载ANKI探索版：<a href="https://www.ankichinas.cn/" target="_blank">https://www.ankichinas.cn/</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            ANKI中文资源网：<a href="https://file.ankichinas.cn/" target="_blank">https://file.ankichinas.cn</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            我的知乎主页：<a href="https://www.zhihu.com/people/woshikakali" target="_blank">https://www.zhihu.com/people/woshikakali</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            ANKI微信交流：加我微信，我拉你进群
          </p>
          <ol style="font-size:15px;color:#404040;">
            <li>
              扫一扫下图<br/>
            </li>
            <li>
              或输入微信号添加：woshikakali
            </li>
          </ol>
          <div>
            <img src="/static/images/wxqrcode.png" width="300">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "about"
  }
</script>

<style scoped>

</style>
